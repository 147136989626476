body {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  letter-spacing: 0.02em;
  color: black;
  outline: 0;
  /* overflow: hidden; */
}

/* ----------------------------------------
Player
------------------------------------------- */

.iconBtn {
  background-color: white;
  /* color: white; */
  cursor: pointer;
  color: #49688c;
  /* background-color: #49688c; */
  border-radius: 150px;
  opacity: 0.5;
  transition: 1s opacity;
}

.iconBtn:hover {
  opacity: 1;
}

@media screen and (min-width: 890px) {
  section .player-container {
    width: 100%;
    height: 500px;
  }
}

.player-container {
  background-color: #f1f6fc;
  position: relative;
  width: 375px;
  height: 375px;
  padding: 0 !important;
}

@media screen and (max-width: 480px) {
  section .player-container {
    max-width: fit-content;
    height: 425px;
  }
  .player-container {
    max-width: 280px;
    height: 280px;
  }

  .closeIcon {
    display: none;
  }
}
@media screen and (max-width: 320px) {
  .player-container {
    width: 200px;
    height: 200px;
  }
}

.react-player {
  /* position: absolute; */
  background-color: #f1f6fc;
}

.react-player video {
  outline: transparent;
  height: 100%;
}

.closeIcon {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  border-radius: 150px;
  color: #7aaeea;
  top: 10px;
  right: 10px;
  /* margin-right: 10px; */
}

.responsive-player-wrapper {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  overflow-y: hidden;
}

.responsive-react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* ----------------------------------------
Text
------------------------------------------- */

.bold {
  color: #7aaeea;
  font-weight: 600;
  font-style: italic;
}

.ital {
  font-style: italic;
  font-weight: 500;
}

/* ----------------------------------------
Bio Dialogs
------------------------------------------- */

.bio-dialog {
  height: 90vh;
  margin: auto;
}

.bio-dialog-title .MuiTypography-h6 {
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
}

.bio-dialog .bio-dialog-content {
  padding: 30px;
}

.bio-dialog .MuiDialogContentText-root {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: black;
  line-height: 1.3em;
  margin-bottom: 30px;
  text-align: justify;
}

.bio-dialog .last-p {
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  .bio-dialog {
    height: 80vh;
  }
}

@media screen and (max-width: 450px) {
  .bio-dialog .MuiDialog-paper {
    margin: auto 10px;
  }

  .bio-dialog .bio-dialog-content {
    padding: 25px;
  }

  .bio-dialog .MuiDialogContentText-root {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    text-align: justify;
  }
}

/* ----------------------------------------
Testimonial Text on Phone
------------------------------------------- */
/* Hide and show testimonial stories */
.show-enter {
  opacity: 0;
  position: relative;
  transform: translateY(-100%);
}
.show-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms ease-in, transform 500ms ease-in-out;
}
.show-exit {
  opacity: 1;
}
.show-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 300ms linear, transform 500ms ease-in-out;
}

/* Style for divider */

.divider {
  border-bottom: 2px black solid;
}

/* Style for divider */

.divider {
  border-bottom: 2px black solid;
}


@media screen and (min-width: 768px) {
  .photo16 {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .photo16 {
    display: flex !important;
  }
}